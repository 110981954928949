import dayjs from 'dayjs';

import { isBetweenMinMaxDepartureDate, getMinDepartureDate, getDateYMD } from './dateUtils';

export function getValidDateRangeFromParams(
	rawMinDate: string | dayjs.Dayjs,
	rawMaxDate: string | dayjs.Dayjs,
): string[] | undefined {
	const minDate = dayjs(rawMinDate, 'YYYY-MM-DD');
	const maxDate = dayjs(rawMaxDate, 'YYYY-MM-DD');

	if (!maxDate.isValid() || !isBetweenMinMaxDepartureDate(maxDate)) {
		return undefined;
	}

	if (!minDate.isValid() || !minDate.isSameOrBefore(maxDate)) {
		return undefined;
	}

	const validMinDate = isBetweenMinMaxDepartureDate(minDate) ? minDate : getMinDepartureDate();

	if (validMinDate.isSame(maxDate)) {
		return [getDateYMD(maxDate)];
	}

	return [getDateYMD(validMinDate), getDateYMD(maxDate)];
}
