import { Footer as FooterComponent } from '@vakantiesnl/components/src/__LEGACY__/organisms/Footer';
import { useFooterContext } from '@vakantiesnl/services/src/context/footerContext';
import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';
import { FooterBlock } from '@vakantiesnl/types';

import { LayoutProps } from '../../components/templates/Layout';

const Footer: LayoutProps['Footer'] = ({ pageType }) => {
	const footerContext = useFooterContext() as FooterBlock;
	const microCopies = useMicroCopyContext();

	return footerContext ? <FooterComponent {...footerContext} microCopies={microCopies} pageType={pageType} /> : null;
};

export default Footer;
