export const inject = (
	str?: string,
	obj?: {
		[key: string]: string | JSX.Element;
	},
): (string | JSX.Element)[] => {
	if (str && obj) {
		return str.split(/\${(.*?)}/g).map((g) => obj[g] || g);
	}
	return [];
};
