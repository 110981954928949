type Props = {
	input: RequestInfo;
	correlationId?: string;
	init?: RequestInit;
};

export const fetchVakNL = ({ input, init, correlationId }: Props): Promise<Response> =>
	fetch(
		input,
		correlationId
			? {
					...(init || {}),
					headers: {
						...(init?.headers || {}),
						'Content-Type': 'application/json',
						'X-Vaknl-Correlation-Id': correlationId,
					},
				}
			: init,
	);
