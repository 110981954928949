import { mapValues } from 'lodash';

export function replaceUndefinedByNull<T>(value: T): T {
	if (value === undefined) return null as T;

	if (value === null) return value;

	if (Array.isArray(value)) {
		for (let index = 0; index < value.length; index++) {
			value[index] = replaceUndefinedByNull(value[index]);
		}
	} else if (typeof value === 'object') {
		return mapValues(value, replaceUndefinedByNull) as T;
	}

	return value;
}
