export const sortObject = (subject) => {
	const result = {};
	const sortedKeys = Object.keys(subject).sort();

	for (let i = 0; i < sortedKeys.length; ++i) {
		const key = sortedKeys[i];
		let value = subject[key];

		if (value != null && typeof value === 'object' && !Array.isArray(value)) {
			value = sortObject(value);
		}

		result[key] = value;
	}
	return result;
};
