import { Header as HeaderComponent } from '@vakantiesnl/components/src/__LEGACY__/organisms/Header';
import { useHeaderContext } from '@vakantiesnl/services/src/context/headerContext';
import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';
import { VaknlHeaderBlock } from '@vakantiesnl/types';

import { LayoutProps } from '../../components/templates/Layout';

const Header: LayoutProps['Header'] = ({ pageType }) => {
	const headerContext = useHeaderContext<VaknlHeaderBlock>();
	const microCopies = useMicroCopyContext();

	return headerContext && !!Object.keys(headerContext)?.length ? (
		<HeaderComponent {...headerContext} microCopies={microCopies} pageType={pageType} showTopBar={true} />
	) : null;
};

export default Header;
