import { errorHandler } from '@vakantiesnl/services/src/util/errorHandling';

import { StatusCodeError, parseErrorToReport } from './errorHandling';

type Options = {
	/** The fetch function, json response body expected */
	fetchFn: () => Promise<Response>;
	/** Error message to send to the error logs along with the error itself for easier identification */
	errorMessage: string;
	/** Any extra data to send to the error logs, e.g. for debugging an issue */
	logOnError?: string;
	/** Callback to handle the error response in case the request was not ok, json response body expected */
	onStatusCodeError?: (statusCode: number, response?: unknown) => void;
};

// Wrapper around fetch to handle any status code or other unexpected errors
export async function fetchHandler<T>({ fetchFn, errorMessage, logOnError, onStatusCodeError }: Options): Promise<T> {
	try {
		const response = await fetchFn();

		if (!response.ok) {
			if (onStatusCodeError) {
				// Try to parse response for possible error message
				response
					.json()
					.then((res) => onStatusCodeError(response.status, res))
					.catch(() => onStatusCodeError(response.status));
			}

			throw new StatusCodeError(response.status);
		}

		const data = await response.json();
		return data;
	} catch (e) {
		// This reports the error when it happened on the client
		if (logOnError) {
			errorHandler.report(parseErrorToReport(e, `${errorMessage} with data: ${logOnError}`));
		} else {
			errorHandler.report(parseErrorToReport(e, errorMessage));
		}

		// This will display the error in the console and report the error when it happened on the server
		// eslint-disable-next-line no-console
		console.error(errorMessage, e);

		// Throw an error, so we can decide at a higher level how we want to handle it.
		// For example React Query hooks resolve errors to the isError/error props.
		if (e instanceof Error) {
			throw e;
		} else {
			throw new Error(errorMessage);
		}
	}
}
